import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ArtConsulting from "../components/ArtConsulting/ArtConsulting"

const ConsultingPage = () => (
  <Layout>
    <Seo title="Fine Art" />
    <ArtConsulting />
  </Layout>
)

export default ConsultingPage
