import React, { Component } from 'react';

import * as styles from './art_consulting.module.scss';
import consultingImage from './images/consulting.png';
import artOne from './images/art_gallery.png';
import artTwo from './images/DSC_3232.png';
import artThree from './images/DSC_3315.png';
import artFour from './images/art_gallery_2.png';

class ArtConsulting extends Component {
  render() {
    return (
      <section className={styles.artConsulting}>
        <h1>ASTUTE ART CONSULTING</h1>
        <div className={styles.artPageContent}>
          <div className={styles.imageContent}>
            <img src={consultingImage} alt=""/>
          </div>
          <div className={styles.textContent}>
            <p>- <strong>90% of visual</strong> information that reaches the eye <strong>is lost</strong> by the time it reaches the brain.</p>
            <p> - Strong <strong>binocular rivalry</strong> occurs during the visual processing stage when dissimilar visuals are presented. People are not able to determine what they actually see.</p>
            <p>- Imbalance of incorrect hypotheses leads to errors of perception and <strong>misbalance in design</strong> concept.</p>
            <p>- The fundamental principle of Gestalt says that we prefer things that are simple, clear, and ordered. They take less time for us to process and more time to <strong>stick to our memory.</strong></p>
            <br/>
            <p>This is why understanding the psychology of memory is so important in creating harmonious and memorable spaces.</p>

          </div>
        </div>
        <div className={styles.consultingText}>
          <p>
            Zaru sees the whole space as a canvas with a distinctive composition.
            Her knowledge of matching artwork and design elements helps interior
            designers achieve the desired atmosphere and ensures the space will be
            perceived as a unified organism.
          </p>
          <p>
            Zaru helps create a rhythm of eye movement that allows visitors to
            experience holistic design. She strives for a balance between simplicity
            and variety, and she arranges design elements to draw the eye along a
            comfortable pattern of movement.
            <br/>
            <strong>Psychology of memory</strong> and <strong>ancient Eastern philosophies</strong> are key elements
            for creating a harmonious atmosphere, where spirituality meets design.
            Zaru helps designers choose artwork that will enhance their vision and
            deliver an <strong>unforgettable experience.</strong>
          </p>

          <a href="mailto:hi@yuliazaru.com?Subject=Consulting">Send your message</a>
        </div>
        <div className={styles.consultingGallery}>
          <div className={styles.galleryRow}>
            <img src={artOne} alt=""/>
            <img src={artTwo} alt=""/>
          </div>
          <div className={styles.galleryRow}>
            <img src={artThree} alt=""/>
            <img src={artFour} alt=""/>
          </div>
        </div>
      </section>
    );
  }
}

export default ArtConsulting;
